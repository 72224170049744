.appbar-credit {
  font-size: 14px;
  margin-left: 4px;
  color: #000;
  font-weight: 500;
}

.contained-btn {
  background: #ffcb00 !important;
}

.mobile-btn {
  border: none;
  outline: none;
  border-radius: 0.3rem;
  padding: 0.5rem 0.6rem;

  font-size: 0.85rem;
  font-weight: 500;
  color: #000;
}

.MuiPaper-elevation4 {
  box-shadow: none !important;
}

.image-logo {
  margin-right: 9%;
}

.margin-spacing-right {
  margin-right: 20px !important;
}

.icon-style {
  height: 40px;
  width: 40px;
}

.message-icon-style {
  height: 18px;
  width: 18px;
  right: 22px;
  position: absolute;
}

.bell-icon-style {
  height: 18px;
  width: 18px;
  right: 22px;
  position: absolute;
}

/* css for search */

body {
  font-family: "Open Sans", sans-serif;
}

ul {
  margin: 0;
  padding: 0;
}

li {
  /* list-style: none; */
}

.container {
  display: flex;
  flex-direction: column;

  padding-bottom: 40px;
}

.input_container {
  min-width: 0;

  /* position: relative; */
  /* &::before {
    left: 0;
    right: 0;
    bottom: 0;
    content: '\00a0';
    position: absolute;
    transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-bottom: solid 2px #979797;
    pointer-events: none;
  }
  &::after {
    left: 0;
    right: 0;
    bottom: 0;
    content: '';
    position: absolute;
    transform: scaleX(0);
    transition: transform 300ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    
    pointer-events: none;
  }
  &:focus-within {
    &::after {
      transform: scaleX(1);
    }
  } */
}

.input_container::before {
  left: 0;
  right: 0;
  bottom: 0;
  content: "\00a0";
  position: absolute;
  transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  /* border-bottom: solid 10px red; */
  pointer-events: none;
}

.input_container::after {
  left: 0;
  right: 0;
  bottom: 0;
  content: "";
  position: absolute;
  transform: scaleX(0);
  transition: transform 300ms cubic-bezier(0, 0, 0.2, 1) 0ms;

  pointer-events: none;
}

.input_container:focus-within::after {
  transform: scaleX(1);
}

.input {
  width: 100% !important;
  height: 40px !important;
  position: unset !important;
  opacity: 1 !important;
  border: none !important;
  padding: 0px 50px !important;
}

.input:focus-visible {
  outline: none !important;
}

.input::placeholder {
  color: #b3b3b3;
}

.dropdown {
  width: 100%;
  min-width: 0;
  /* height: 100%; */
  position: absolute;
  overflow-x: hidden;
  /* border: 1px solid #b3b3b3; */
  background-color: #fff;
  visibility: hidden;
  z-index: 1301;
  left: 0px;
  top: 30px;
  border-right: 1px solid #aaaaaa50;
  border-left: 1px solid #aaaaaa50;
  border-bottom: 1px solid #aaaaaa50;
  border-radius: 0px 0px 10px 10px;
}

.dropdown.v {
  visibility: visible;
}

.dropdown.v .MuiAccordionSummary-expandIcon.Mui-expanded {
  transform: rotate(90deg) !important;
}

.dropdown.v .MuiPaper-root.MuiAccordion-root.MuiAccordion-rounded.MuiPaper-elevation1.MuiPaper-rounded {
  border: none !important;
  border-radius: none !important;
}

.dropdown_item {
  width: 100%;
  max-width: 407px;
  padding: 12px;
  cursor: pointer;
  /* &:hover {
    background-color: #f1f1f1d7;
  } */
  border-bottom: 1px solid #aaaaaa50;
}

.item_text1 {
  color: #090a3c;
  margin-bottom: 5px;
}

.item_text2 {
  font-size: 14px;
  color: #090a3c80;
}

.search-test {
  border: 1px solid #aaaaaa50;
  border-radius: 10px;
  overflow: hidden;
}

.accordion-list {
  max-height: calc(100vh - 80px);
}

.user-list-outter .list-name {
  max-width: 245px;
  padding-right: 5px;
}

.btn-connected {
  border-radius: 25px !important;
  background: #010138 !important;
  color: #fff !important;
}

/* Header profile drop down CSS */
.popup {
  width: 180px;
  margin: 0 auto;
  position: absolute;
  top: 124%;
  right: 0px;
  z-index: 999;
  border-radius: 15px;
  box-shadow: 1px 3px 24px rgb(0 0 0 / 50%);
}

.arrow {
  width: 22px;
  height: 38px;
  transform: rotate(-47deg);
  box-shadow: 0 1px 14px rgb(0 0 0 / 20%);
  background: #fff;
  position: absolute;
  z-index: 998;
  /* width: 0; */
  /* height: 0; */
  /* border-left: 15px solid #0002; */
  /* border-right: 15px solid #0002; */
  /* border-bottom: 17px solid white; */
  /* border-radius: 100% 100% 0px 0px; */
  top: -13px;
  right: 17px;
  border-radius: 5px;
  background: #fcf0c4;
}

.noP {
  padding: 0px !important;
}

.top-heading-popup {
  padding: 15px;
  font-size: 18px;
  font-weight: 600;
  color: #010138;
  background: #fcf0c4;
  word-break: break-word;
}

.popup-wrapper p {
  font-size: 18px;
  font-weight: 600;
  color: #010138;
  text-align: left;
  padding: 15px 25px !important;
  transition: color .5s ease-in;
}

.popup-wrapper p:hover {
  background-color: rgba(0, 0, 0, 0.06);
}

/* .popup:before {
  content: "";
  width: 16px;
  height: 20px;
  transform: rotate(45deg);
  box-shadow: 0 1px 14px rgba(0,0,0,.2);
  background: #fff;
  position: absolute;
  z-index: 998;
  
} */
/*
Different arrow positioning
*/
/* .popup.arrow-top:before {
  left: calc(50% - -50px);
  top: -8px;
}
.popup.arrow-right:before {
  top: calc(50% - 10px);
  right: -8px;
}
.popup.arrow-bottom:before {
  left: calc(50% - 10px);
  bottom: -8px;
}
.popup.arrow-left:before {
  top: calc(50% - 10px);
  left: -8px;
} */

/* 
Popup wrapper
This is where we add the white background and padding of the content within the popup
*/
.popup-wrapper {
  width: 100%;
  border-radius: 15px;
  background: #ffffff;
  position: relative;
  z-index: 998;
  text-align: center;
  overflow: hidden;
}

.popup-wrapper p {
  border-bottom: 1px solid #e9e9e9;
  margin: 0px;
}

.notificationDot {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #111075;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  position: absolute;
  right: 5px;
  top: 10px;
  color: #fff;
  font-size: 70%;
}

.noDataFoundSearch {
  display: flex;
  height: 220px;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.noDataFoundSearch img {
  height: 140px;
  width: 140px;
}

.noDataFoundSearch span {
  width: 100%;
  margin-bottom: 25px;
  text-align: center;
}

.notification-card {
  transition: all 0.5s ease;
}

.notification-card:hover {
  background: #ccc;
}
@keyframes shakeTilt {
  0% { transform: rotate(0deg); }
  25% { transform: rotate(-45deg); }
  50% { transform: rotate(45deg); }
  75% { transform: rotate(-45deg); }
  100% { transform: rotate(0deg); }
}

.icon-shake {
  animation: shakeTilt 0.5s ease-in-out forwards;
}

.icon-shake-color {
  background-color: #cbd9f0 !important;
}

/* Define keyframes for fade-in and fade-out effects */
@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
    transform: translateY(0); 
  }
  100% {
    opacity: 0;
    transform: translateY(-20px); 
  }
}

/* Base app-bar styles */
.app-bar {
  transition: opacity 0.6s ease, transform 0.6s ease;
}

/* When header should be shown */
.app-bar.show {
  animation: fadeIn 0.6s ease-in-out forwards;
}

/* When header should be hidden */
.app-bar.hide {
  animation: fadeOut 0.6s ease-in-out forwards;
}


